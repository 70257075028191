import { useDeliveryOptions } from "../../../../context/delivery";
import { useStep } from "../../../../context/step";
import { Button } from "../../../Button";
import styles from "./Fuel.module.css";
import { ReactComponent as Flame } from "./flame.svg";

export function Fuel() {
  const { setStep } = useStep();
  const { selectedOption, setSelectedFuelType } = useDeliveryOptions();

  return (
    <div className={styles.Fuel}>
      <h2>Select your type of kerosene</h2>

      <div className={styles.options}>
        {selectedOption &&
          selectedOption?.prices.kerosene.price_per_litre > 0 && (
            <div className={styles.option}>
              <div className={styles.flames}>
                <Flame />
              </div>
              <h3>Standard Kerosene</h3>

              <ul>
                <li>Standard heating oil for your home</li>
                <li>Safe for domestic storage</li>
                <li>More efficient than other fuel types</li>
              </ul>

              <div className={styles.totals}>
                <div className={styles.price_per_litre}>
                  {selectedOption.prices.kerosene.price_per_litre} PPL (ex VAT)
                </div>
                <div className={styles.total_exclude_vat}>
                  &pound;
                  {selectedOption.prices.kerosene.total_exclude_vat.toFixed(
                    2
                  )}{" "}
                  (ex VAT)
                </div>
                <div className={styles.price}>
                  <strong>
                    &pound;{selectedOption.prices.kerosene.total.toFixed(2)}
                  </strong>{" "}
                  (inc VAT)
                </div>
              </div>

              <div className={styles.button}>
                <Button
                  size="small"
                  onClick={() => {
                    setSelectedFuelType("kerosene");
                    setStep(3);
                  }}
                >
                  Select & Continue
                </Button>
              </div>
            </div>
          )}
        {selectedOption &&
          selectedOption?.prices.kerosenepremium.price_per_litre > 0 && (
            <div className={styles.option}>
              <div className={styles.flames}>
                <Flame />
                <Flame />
              </div>
              <h3>Premium Kerosene</h3>

              <ul>
                <li>Clean & safe for domestic storage </li>
                <li>Improved efficiency vs other fuel types</li>
                <li>Clean & hot burn for efficient fuel</li>
                <li>Reduces tank sludge build up</li>
                <li>Cleaner burn, reducing emissions</li>
                <li>Longer lasting for optimum economy</li>
              </ul>

              <div className={styles.price_per_litre}>
                {selectedOption.prices.kerosenepremium.price_per_litre} PPL (ex
                VAT)
              </div>
              <div className={styles.total_exclude_vat}>
                &pound;
                {selectedOption.prices.kerosenepremium.total_exclude_vat.toFixed(
                  2
                )}{" "}
                (ex VAT)
              </div>
              <div className={styles.price}>
                <strong>
                  &pound;
                  {selectedOption.prices.kerosenepremium.total.toFixed(2)}
                </strong>{" "}
                (inc VAT)
              </div>

              <div className={styles.button}>
                <Button
                  size="small"
                  onClick={() => {
                    setSelectedFuelType("kerosenepremium");
                    setStep(3);
                  }}
                >
                  Select & Continue
                </Button>
              </div>
            </div>
          )}
      </div>
    </div>
  );
}
