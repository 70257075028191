import { Quote } from "./components";
import "./App.css";
import { StepProvider } from "./context/step";
import { QuoteProvider } from "./context/quote";
import { DeliveryOptionsProvider } from "./context/delivery";
import { Toaster } from "react-hot-toast";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
interface QuoteAppProps {
  endpoint: string;
}

interface CustomWindow extends Window {
  ScriptsConfig?: {
    customRecaptchaSiteKey?: string;
  };
}

const QuoteApp = ({ endpoint }: QuoteAppProps) => {
  const customWindow = window as CustomWindow;

  const customKey =
    customWindow.ScriptsConfig?.customRecaptchaSiteKey?.valueOf();
  const reCaptchaKey = customKey
    ? customKey
    : "6Levn1MdAAAAALNQI9lhSdY5mZKhzjQVuQvqlpG_";
  return (
    <>
      <Toaster />
      <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
        <StepProvider>
          <QuoteProvider defaultEndpoint={endpoint}>
            <DeliveryOptionsProvider>
              <Quote />
            </DeliveryOptionsProvider>
          </QuoteProvider>
        </StepProvider>
      </GoogleReCaptchaProvider>
    </>
  );
};

export default QuoteApp;
