import bankHolidays from "./futureBankHolidays.json";

export interface DeliveryCalcProps {
  delivery_key: string;
}

export const addWorkingDays = (currentDate: Date, numberOfDays: number) => {
  // if time is after 2pm, add 1 day
  if (currentDate.getHours() >= 16) {
    currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
  }

  while (numberOfDays > 0) {
    currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));

    const isBankHoliday = bankHolidays.includes(
      currentDate.toISOString().split("T")[0]
    );
    // If current day is not a Saturday (6) or a Sunday (0) and not a bank holiday, then reduce the number of days
    if (
      currentDate.getDay() !== 0 &&
      currentDate.getDay() !== 6 &&
      !isBankHoliday
    ) {
      numberOfDays--;
    }
  }

  return currentDate;
};

export function DeliveryCalc({ delivery_key }: DeliveryCalcProps) {
  const getToDate = (dateType: string) => {
    let currentDate: Date = new Date();
    switch (dateType) {
      case "day10":
        // 10 working days from today
        return addWorkingDays(currentDate, 10);
      case "day5":
        // 5 working days from today
        return addWorkingDays(currentDate, 5);
      case "day1":
        // 1 working day from today
        return addWorkingDays(currentDate, 2);
      default:
        return addWorkingDays(currentDate, 10);
    }
  };

  return (
    <>
      {getToDate(delivery_key).toLocaleDateString("en-GB", {
        weekday: "short",
        month: "short",
        day: "numeric",
      })}
    </>
  );
}
