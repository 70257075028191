import toast from "react-hot-toast";
import { useDeliveryOptions } from "../../../../context/delivery";
import { useQuote } from "../../../../context/quote";
import { Button } from "../../../Button";
import styles from "./Delivery.module.css";
import { useStep } from "../../../../context/step";
import { useEffect, useState } from "react";
import { Spinner } from "../../../Spinner";

export function Delivery() {
  const {
    address,
    setAddress,
    selectedFuelType,
    selectedDateType,
    billingAddress,
    setBillingAddress,
    useBillingAddress,
    setUseBillingAddress,
  } = useDeliveryOptions();
  const { postalCode, endpoint } = useQuote();
  const { setStep } = useStep();
  const [fetching, setFetching] = useState(false);
  const [confirmed, setConfirmed] = useState(false);

  useEffect(() => {
    const payload = {
      delivery_type: selectedDateType,
      product_sku: selectedFuelType,
      add_carbon_offset: false,
    };

    const url = `${endpoint}/rest/V1/quote/select`;
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(payload),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.message) {
          toast.error(data.message);
          setFetching(false);
          if (
            data.message.toLowerCase().includes("quote expired") ||
            data.message.toLowerCase().includes("please go back to step 1")
          ) {
            setStep(0);
          }
          return;
        }
      });
  }, [endpoint, selectedDateType, selectedFuelType, setStep]);

  if (fetching) {
    return (
      <div className={styles.Delivery}>
        <Spinner />
      </div>
    );
  }

  const selectFuel = () => {
    if (!selectedFuelType) {
      toast.error("Quote expired, Please submit the quote form again");
      setStep(2);
      return;
    }
    if (!selectedDateType) {
      toast.error("Quote expired, Please submit the quote form again");
      setStep(1);
      return;
    }
    if (!address.firstName) {
      toast.error("Please enter your first name");
      return;
    }
    if (!address.lastName) {
      toast.error("Please enter your last name");
      return;
    }
    if (!address.phone) {
      toast.error("Please enter your phone number");
      return;
    }
    if (!address.address1) {
      toast.error("Please enter your address");
      return;
    }
    if (!address.town) {
      toast.error("Please enter your town/city");
      return;
    }

    if (!useBillingAddress) {
      if (!billingAddress.firstName) {
        toast.error("Please enter your billing first name");
        return;
      }
      if (!billingAddress.lastName) {
        toast.error("Please enter your billing last name");
        return;
      }
      if (!billingAddress.phone) {
        toast.error("Please enter your billing phone number");
        return;
      }
      if (!billingAddress.address1) {
        toast.error("Please enter your billing address");
        return;
      }
      if (!billingAddress.town) {
        toast.error("Please enter your billing town/city");
        return;
      }
      if (!billingAddress.postcode) {
        toast.error("Please enter your billing postcode");
        return;
      }
    }

    if (!confirmed) {
      toast.error(
        "Please confirm that you are purchasing heating oil for Standard Domestic use"
      );
      return;
    }
    setFetching(true);

    const element = document.getElementById("ho-widget");
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }

    proceedToPayment();
  };

  const proceedToPayment = () => {
    const payload = {
      telephone: address.phone,
      firstname: address.firstName,
      lastname: address.lastName,
      street_1: address.address1,
      street_2: address.address2,
      city: address.town,
      region: "",
      postcode: postalCode,
      same_as_billing: useBillingAddress,
      delivery_notes: ` ${address.contact ? "CDB4 " : ""} ${
        address.instructions
      }`,
      contact_before_delivery: address.contact,
      billing_street_1: billingAddress.address1,
      billing_street_2: billingAddress.address2,
      billing_city: billingAddress.town,
      billing_region: "",
      billing_postcode: billingAddress.postcode,
      preferences_email: false,
      preferences_sms: false,
      preferences_post: false,
      delivery_type: selectedDateType,
    };

    fetch(`${endpoint}/rest/V1/quote/addToCart`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(payload),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.message) {
          setFetching(false);
          toast.error(data.message);
          if (
            data.message.toLowerCase().includes("quote expired") ||
            data.message.toLowerCase().includes("please go back to step 1")
          ) {
            setStep(0);
          }
          return;
        }
        data = data[0];
        if (data.status === "success") {
          fetch(`${endpoint}/quote/cart/add`, {
            method: "POST",
            credentials: "include",
          }).then(() => {
            window.location.href = `${endpoint}${data.data.redirect_url}`;
          });
        }
      });
  };

  return (
    <div className={styles.Delivery}>
      <h2>Your Details</h2>
      <div className={styles.form}>
        <div className={styles.fields}>
          <div className={styles.field}>
            <label htmlFor="firstname">* First Name</label>
            <input
              className={styles.input}
              id="firstname"
              placeholder="Enter your first name"
              defaultValue={address.firstName}
              onChange={(e) => {
                setAddress({
                  ...address,
                  firstName: e.target.value,
                });
              }}
            />
          </div>
          <div className={styles.field}>
            <label htmlFor="lastname">* Last Name</label>
            <input
              className={styles.input}
              id="lastname"
              placeholder="Enter your first name"
              defaultValue={address.lastName}
              onChange={(e) => {
                setAddress({
                  ...address,
                  lastName: e.target.value,
                });
              }}
            />
          </div>
          <div className={styles.field}>
            <label htmlFor="phone">* Phone Number</label>
            <input
              className={styles.input}
              id="phone"
              defaultValue={address.phone}
              placeholder="Enter phone number"
              onChange={(e) => {
                setAddress({
                  ...address,
                  phone: e.target.value,
                });
              }}
            />
          </div>
        </div>
      </div>
      <h2>Delivery Address</h2>

      <div className={styles.form}>
        <div className={styles.fields}>
          <div className={styles.field}>
            <label htmlFor="address">* Address</label>
            <input
              className={styles.input}
              id="address"
              defaultValue={address.address1}
              placeholder="Enter your address"
              onChange={(e) => {
                setAddress({
                  ...address,
                  address1: e.target.value,
                });
              }}
            />
          </div>
          <div className={styles.field}>
            <label htmlFor="address2">&nbsp;</label>
            <input
              className={styles.input}
              id="address2"
              defaultValue={address.address2}
              placeholder="Enter address line 2"
              onChange={(e) => {
                setAddress({
                  ...address,
                  address2: e.target.value,
                });
              }}
            />
          </div>
          <div className={styles.field}>
            <label htmlFor="town">* Town/City</label>
            <input
              className={styles.input}
              id="town"
              defaultValue={address.town}
              placeholder="Enter town/city"
              onChange={(e) => {
                setAddress({
                  ...address,
                  town: e.target.value,
                });
              }}
            />
          </div>
          <div className={styles.field}>
            <label htmlFor="postcode">Postcode</label>
            <input
              className={styles.input}
              id="postcode"
              defaultValue={postalCode}
              disabled={true}
            />
          </div>
        </div>

        <div className={styles.fullfields}>
          <div className={styles.field}>
            <div className={styles.checkField}>
              <input
                type="checkbox"
                id="same_as_billing"
                checked={useBillingAddress}
                onChange={(e) => {
                  setUseBillingAddress(e.target.checked);
                }}
              />
              <label htmlFor="same_as_billing">
                Use same address for billing
              </label>
            </div>
          </div>
        </div>
      </div>
      {!useBillingAddress && (
        <>
          <h2>Billing Address</h2>
          <div className={styles.form}>
            <div className={styles.fields}>
              <div className={styles.field}>
                <label htmlFor="billing_firstname">* First Name</label>
                <input
                  className={styles.input}
                  id="billing_firstname"
                  placeholder="Enter your first name"
                  defaultValue={billingAddress.firstName}
                  onChange={(e) => {
                    setBillingAddress({
                      ...billingAddress,
                      firstName: e.target.value,
                    });
                  }}
                />
              </div>

              <div className={styles.field}>
                <label htmlFor="billing_lastname">* Last Name</label>
                <input
                  className={styles.input}
                  id="billing_lastname"
                  placeholder="Enter your first name"
                  defaultValue={billingAddress.lastName}
                  onChange={(e) => {
                    setBillingAddress({
                      ...billingAddress,
                      lastName: e.target.value,
                    });
                  }}
                />
              </div>
              <div className={styles.field}>
                <label htmlFor="billing_phone">* Phone Number</label>
                <input
                  className={styles.input}
                  id="billing_phone"
                  placeholder="Enter phone number"
                  defaultValue={billingAddress.phone}
                  onChange={(e) => {
                    setBillingAddress({
                      ...billingAddress,
                      phone: e.target.value,
                    });
                  }}
                />
              </div>
            </div>

            <div className={styles.fields}>
              <div className={styles.field}>
                <label htmlFor="billing_address">* Address</label>
                <input
                  className={styles.input}
                  id="billing_address"
                  placeholder="Enter your address"
                  defaultValue={billingAddress.address1}
                  onChange={(e) => {
                    setBillingAddress({
                      ...billingAddress,
                      address1: e.target.value,
                    });
                  }}
                />
              </div>
              <div className={styles.field}>
                <label htmlFor="billing_address2">&nbsp;</label>
                <input
                  className={styles.input}
                  id="billing_address2"
                  placeholder="Enter address line 2"
                  defaultValue={billingAddress.address2}
                  onChange={(e) => {
                    setBillingAddress({
                      ...billingAddress,
                      address2: e.target.value,
                    });
                  }}
                />
              </div>
              <div className={styles.field}>
                <label htmlFor="billing_town">* Town/City</label>
                <input
                  className={styles.input}
                  id="billing_town"
                  placeholder="enter town/city"
                  defaultValue={billingAddress.town}
                  onChange={(e) => {
                    setBillingAddress({
                      ...billingAddress,
                      town: e.target.value,
                    });
                  }}
                />
              </div>
              <div className={styles.field}>
                <label htmlFor="billing_postcode">* Postcode</label>
                <input
                  className={styles.input}
                  id="billing_postcode"
                  placeholder="enter postcode"
                  defaultValue={billingAddress.postcode}
                  onChange={(e) => {
                    setBillingAddress({
                      ...billingAddress,
                      postcode: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </>
      )}
      <div className={styles.form}>
        <h2>Delivery Comments For Drivers</h2>
        <div className={styles.fullfields}>
          <div className={styles.field}>
            <label htmlFor="notes">Delivery Instructions</label>
            <textarea
              className={styles.input}
              id="notes"
              defaultValue={address.instructions}
              placeholder={`Please add additional comments here.\n E.g. Dog(s) on property, Locked tank/gate.`}
              onChange={(e) => {
                setAddress({
                  ...address,
                  instructions: e.target.value,
                });
              }}
            />
          </div>
        </div>
        <div className={styles.fullfields}>
          <div className={styles.field}>
            <div className={styles.checkField}>
              <input
                type="checkbox"
                id="confirmed"
                checked={confirmed}
                onChange={(e) => {
                  setConfirmed(e.target.checked);
                }}
              />
              <label htmlFor="confirmed">
                I confirm that I am purchasing heating oil for standard domestic
                use.
              </label>
            </div>
          </div>
        </div>
        <div
          className={`${styles.button} ${
            confirmed === false && styles.disabled
          }`}
        >
          <Button size="large" onClick={selectFuel}>
            Secure Checkout
          </Button>
        </div>
      </div>
    </div>
  );
}
