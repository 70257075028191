import React, { createContext, useContext, useState, useCallback } from 'react';

interface FuelTypePriceDetails {
  price_per_litre: number;
  total_exclude_vat: number;
  vat_rate: number;
  vat_amount: number;
  total: number;
}

interface DeliveryOption {
  prices: {
    kerosene: FuelTypePriceDetails;
    kerosenepremium: FuelTypePriceDetails;
  };
  key: string;
  title: string;
}

interface CarbonOffsetData {
  litres: number;
  ppc: number;
  total: number;
  name: string;
}

interface address {
  firstName: string;
  lastName: string;
  phone: string;
  address1: string;
  address2: string;
  town: string;
  instructions?: string;
  contact?: boolean;
  postcode?: string;
}

type DeliveryOptions = DeliveryOption[];

interface DeliveryOptionsContextInterface {
  deliveryOptions: DeliveryOptions | undefined;
  setDeliveryOptions: (options: DeliveryOptions) => void;
  setSelectedDateType: (dateType: string) => void;
  selectedDateType: string | undefined;
  carbonOffsetData: CarbonOffsetData | undefined;
  setCarbonOffsetData: (data: CarbonOffsetData) => void;
  selectedOption: DeliveryOption | undefined;
  selectedFuelType: "kerosene" | "kerosenepremium" | undefined;
  setSelectedFuelType: (fuelType: "kerosene" | "kerosenepremium" | undefined) => void;
  address: address;
  setAddress: (address: address) => void;
  useBillingAddress: boolean;
  setUseBillingAddress: (useBillingAddress: boolean) => void;
  billingAddress: address;
  setBillingAddress: (address: address) => void;
}



export const DeliveryOptionsContext = createContext<DeliveryOptionsContextInterface | undefined>(
  undefined
);

export const useDeliveryOptions = (): DeliveryOptionsContextInterface => {
  const context = useContext(DeliveryOptionsContext);
  if (!context) {
    throw new Error('useDeliveryOptions must be used within a DeliveryOptionsProvider');
  }
  return context;
};

export const DeliveryOptionsProvider = ({ children }: { children: React.ReactNode }) => {
  const [deliveryOptions, setDeliveryOptions] = useState<DeliveryOptions | undefined>(undefined);
  const [selectedDateType, setSelectedDateType] = useState<string | undefined>(undefined);
  const [carbonOffsetData, setCarbonOffsetData] = useState<CarbonOffsetData | undefined>(undefined);
  const [selectedFuelType, setSelectedFuelType] = useState<"kerosene" | "kerosenepremium" | undefined>(undefined);
  const [address, setAddress] = useState<address>({
    firstName: "",
    lastName: "",
    phone: "",
    address1: "",
    address2: "",
    town: "",
    instructions: "",
    contact: false
  });
  const [useBillingAddress, setUseBillingAddress] = useState<boolean>(true);
  const [billingAddress, setBillingAddress] = useState<address>({
    firstName: "",
    lastName: "",
    phone: "",
    address1: "",
    address2: "",
    town: "",
  });

  const validatedSetDeliveryOptions = useCallback((options: DeliveryOptions) => {
    //remove any options that have a price of 0
    options = options.filter((option) => {
      return option.prices.kerosene.price_per_litre > 0;
    });
    setDeliveryOptions(options);
  }, []);


  const selectDateType = (dateType: string) => {
    setSelectedDateType(dateType);
  };

  const selectedOption = deliveryOptions?.find((option) => option.key === selectedDateType);

  const validatedSetCarbonOffsetData = useCallback((data: CarbonOffsetData) => {
    setCarbonOffsetData(data);
  }, []);

  return (
    <DeliveryOptionsContext.Provider
      value={{
        deliveryOptions,
        setDeliveryOptions: validatedSetDeliveryOptions,
        setSelectedDateType: selectDateType,
        carbonOffsetData,
        selectedDateType,
        setCarbonOffsetData: validatedSetCarbonOffsetData,
        selectedOption: selectedOption,
        selectedFuelType,
        setSelectedFuelType,
        address,
        setAddress,
        useBillingAddress,
        setUseBillingAddress,
        billingAddress,
        setBillingAddress
      }}
    >
      {children}
    </DeliveryOptionsContext.Provider>
  );
};
