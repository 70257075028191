import styles from "./Button.module.css";

export interface ButtonProps {
  children?: React.ReactNode;
  onClick?: () => void;
  size?: "tiny" | "small" | "large";
}

export function Button({ children, onClick, size = "large" }: ButtonProps) {
  return (
    <button className={`${styles.Button} ${styles[size]}`} onClick={onClick}>
      {children}
    </button>
  );
}
