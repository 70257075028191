import { useDeliveryOptions } from "../../../../context/delivery";
import { useStep } from "../../../../context/step";
import { Button } from "../../../Button";
import { DeliveryCalc } from "../../../DeliveryCalc";
import styles from "./DeliveryDate.module.css";
import { ReactComponent as Flame } from "./flame.svg";

export interface DateProps {
  prop?: string;
}

export const getDateName = (date: string) => {
  switch (date) {
    case "day10":
      return "Standard";
    case "day5":
      return "Priority";
    case "day1":
      return "Express";
  }
};

const getFlames = (dateType: string) => {
  switch (dateType) {
    case "day10":
      //display 1 svg flame
      return [...Array(1)].map((e, i) => <Flame />);
    case "day5":
      //display 2 svg flames
      return [...Array(2)].map((e, i) => <Flame />);
    case "day1":
      //display 3 svg flames
      return [...Array(3)].map((e, i) => <Flame />);
    default:
      return [...Array(1)].map((e, i) => <Flame />);
  }
};

export function DeliveryDate() {
  const { deliveryOptions, setSelectedDateType } = useDeliveryOptions();
  const { setStep } = useStep();

  if (deliveryOptions && deliveryOptions.length === 1) {
    setSelectedDateType(deliveryOptions[0].key);
    setStep(2);
  }

  return (
    <div className={styles.DeliveryDate}>
      <h2>Select the delivery date that suits you best</h2>
      <div className={styles.options}>
        {deliveryOptions &&
          deliveryOptions.map((option) => {
            return (
              option.prices.kerosene.price_per_litre > 0 && (
                <div key={option.key} className={styles.option}>
                  <div className={styles.flames}>{getFlames(option.key)}</div>
                  <h3>{getDateName(option.key)}</h3>
                  <div className={styles.when}>
                    Delivery on or before{" "}
                    <strong>
                      <DeliveryCalc delivery_key={option.key} />
                    </strong>
                  </div>
                  <div className={styles.price_per_litre}>
                    {option.prices.kerosene.price_per_litre} PPL (ex VAT)
                  </div>
                  <div className={styles.price}>
                    <strong>
                      From: &pound;{option.prices.kerosene.total.toFixed(2)}
                    </strong>{" "}
                    (inc VAT)
                  </div>
                  <div className={styles.button}>
                    <Button
                      size="small"
                      onClick={() => {
                        setSelectedDateType(option.key);
                        setStep(2);
                      }}
                    >
                      Select & Continue
                    </Button>
                  </div>
                </div>
              )
            );
          })}
      </div>
    </div>
  );
}
