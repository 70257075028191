import { useDeliveryOptions } from "../../context/delivery";
import { useQuote } from "../../context/quote";
import { useStep } from "../../context/step";
import { Button } from "../Button";
import { DeliveryCalc } from "../DeliveryCalc";
import { getDateName } from "../Steps/step/DeliveryDate";
import styles from "./Progress.module.css";

export function Progress() {
  const { quantity, postalCode, email } = useQuote();
  const { step, setStep } = useStep();
  const { selectedOption, selectedFuelType, deliveryOptions } =
    useDeliveryOptions();

  if (step < 1) {
    return null;
  }

  return (
    <div className={styles.Progress}>
      <div>
        <div className={styles.progressBarContainer}>
          <div
            className={styles.progressBar}
            style={{ width: `calc(33% * ${step} - 15px) ` }}
          >
            {" "}
          </div>
          <div className={`${styles.circle} ${styles.active}`}> </div>
          <div className={`${styles.circle} ${step > 0 ? styles.active : ""}`}>
            {" "}
          </div>
          <div className={`${styles.circle} ${step > 1 ? styles.active : ""}`}>
            {" "}
          </div>
          <div className={`${styles.circle} ${step > 2 ? styles.active : ""}`}>
            {" "}
          </div>
        </div>
      </div>
      <div className={styles.info}>
        <div className={`${styles.step} ${step === 1 && styles.active}`}>
          {step > 0 && (
            <div className={styles.stepInner}>
              <table>
                <tr>
                  <th>Contact</th>
                  <td>{email}</td>
                </tr>
                <tr>
                  <th>Quantity</th>
                  <td>{quantity}L</td>
                </tr>
                <tr>
                  <th>Postcode</th>
                  <td>{postalCode}</td>
                </tr>
              </table>
              <Button size="tiny" onClick={() => setStep(0)}>
                Edit Quantity
              </Button>
            </div>
          )}
        </div>
        <div className={`${styles.step} ${step === 2 && styles.active}`}>
          {step > 1 && selectedOption && (
            <div className={styles.stepInner}>
              <table>
                <tr>
                  <th>Delivery</th>
                  <td>
                    On or before{" "}
                    <strong>
                      <DeliveryCalc delivery_key={selectedOption.key} />
                    </strong>
                  </td>
                </tr>
                <tr>
                  <th>Delivery Type </th>
                  <td>{getDateName(selectedOption.key)} </td>
                </tr>
              </table>
              {deliveryOptions && deliveryOptions.length > 1 && (
                <Button size="tiny" onClick={() => setStep(1)}>
                  Edit Delivery Date
                </Button>
              )}
            </div>
          )}
        </div>
        <div className={`${styles.step} ${step === 3 && styles.active}`}>
          {step > 2 && selectedOption && (
            <div className={styles.stepInner}>
              <table>
                <tr>
                  <th>Fuel Type:</th>
                  <td>
                    {selectedFuelType === "kerosene"
                      ? "Standard Kerosene"
                      : "Premium Kerosene"}
                  </td>
                </tr>
                <tr>
                  <th>PPL</th>
                  <td>
                    {selectedFuelType === "kerosene"
                      ? selectedOption.prices.kerosene.price_per_litre
                      : selectedOption.prices.kerosenepremium
                          .price_per_litre}{" "}
                    (ex VAT)
                  </td>
                </tr>
                <tr>
                  <th>Total</th>
                  <td>
                    <strong>
                      &pound;
                      {selectedFuelType === "kerosene"
                        ? selectedOption.prices.kerosene.total.toFixed(2)
                        : selectedOption.prices.kerosenepremium.total.toFixed(
                            2
                          )}
                    </strong>{" "}
                    (inc VAT)
                  </td>
                </tr>
              </table>
              <Button size="tiny" onClick={() => setStep(2)}>
                Edit Fuel Type
              </Button>
            </div>
          )}
        </div>
      </div>
      {step === 3 && selectedOption && (
        <div className={styles.summmary}>
          <h2>Summary</h2>
          <p>
            You are ordering <strong>{quantity}</strong>L of{" "}
            <strong>
              {selectedFuelType === "kerosene"
                ? "Standard Kerosene"
                : "Premium Kerosene"}
            </strong>
            , which will be delivered on or before{" "}
            <strong>
              <DeliveryCalc delivery_key={selectedOption.key} />
            </strong>{" "}
            to postcode <strong>{postalCode}</strong>. Use the links above to
            amend any of these details.
          </p>
        </div>
      )}
    </div>
  );
}
