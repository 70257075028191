import { UspBar } from "../UspBar";
import styles from "./Quote.module.css";
import { ReactComponent as Flame } from "./svgs/flame.svg";
import { useStep } from "../../context/step";
import { Steps } from "../Steps";
import { Progress } from "../Progress";

export function Quote() {
  const { title, step } = useStep();

  return (
    <section className={styles.Quote} id="ho-widget">
      <div className={styles.inner}>
        {step === 0 && (
          <div className={`${styles.title} ${step > 0 && styles.remove}`}>
            <Flame />
            <h1>{title}</h1>
          </div>
        )}
        <Progress />

        <Steps />
      </div>
      <UspBar />
    </section>
  );
}
