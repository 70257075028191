import styles from "./Requirements.module.css";
import { useQuote } from "../../../../context/quote";
import { useStep } from "../../../../context/step";
import { Button } from "../../../Button";
import { useDeliveryOptions } from "../../../../context/delivery";
import { useCallback, useState } from "react";
import { Spinner } from "../../../Spinner";
import toast from "react-hot-toast";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";

export function Requirements() {
  const {
    endpoint,
    setQuantity,
    setPostalCode,
    setEmail,
    setSmallDelivery,
    email,
    quantity,
    postalCode,
    smallDelivery,
  } = useQuote();
  const { setStep } = useStep();
  const { setDeliveryOptions, setCarbonOffsetData } = useDeliveryOptions();
  const [fetching, setFetching] = useState(false);
  const [token, setToken] = useState<string>("");
  const [signup, setSignup] = useState(false);

  const handleVerify = useCallback((token: string) => {
    setToken(token);
  }, []);

  const makeRequest = () => {
    if (!email) {
      toast.error("Please enter a valid email address");
      return;
    }
    if (!postalCode) {
      toast.error("Please enter a valid postal code");
      return;
    }
    if (!quantity || quantity < 500 || quantity > 5000) {
      toast.error("Please enter a valid quantity, between 500 and 5000 litres");
      return;
    }

    const payload = {
      quantity: quantity,
      fuel_type: "kerosene",
      postcode: postalCode,
      email: email,
      small_tanker: smallDelivery,
      marketing_email: signup,
    };
    setFetching(true);

    fetch(`${endpoint}/rest/V1/quote/submit`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Recaptcha": token,
      },
      credentials: "include",
      body: JSON.stringify(payload),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.message) {
          setFetching(false);
          toast.error(data.message);
          return;
        }
        return data[0].data.product_quote;
      })
      .then((quote) => {
        setDeliveryOptions(JSON.parse(quote.quote_prices));
        setCarbonOffsetData(quote.carbon_offset_data);
        setStep(1);
      })
      .finally(() => {
        setFetching(false);
      });
  };

  if (fetching) {
    return <Spinner />;
  }

  return (
    <div className={styles.Requirements}>
      <div className={styles.header}>
        <strong>
          Fill in your details to get a quick, up-to-date quote for your fuel
          requirements. Our prices are comprehensive with no hidden charges.
        </strong>
      </div>

      <div className={styles.fields}>
        <div className={styles.field}>
          <label htmlFor="quantity">Quantity</label>
          <small>How much fuel do you need? (In litres)</small>
          <input
            className={styles.input}
            id="quantity"
            type="number"
            placeholder="Min 500L, Max 5000L"
            min="500"
            max="5000"
            onChange={(e) => setQuantity(parseInt(e.target.value))}
            defaultValue={quantity}
          />
        </div>
        <div className={styles.field}>
          <label htmlFor="postcode">Postcode</label>
          <small>Where is the fuel to be delivered?</small>
          <input
            className={`${styles.input} ${styles.postcode}`}
            id="postcode"
            type="text"
            placeholder="Enter postcode"
            onChange={(e) => setPostalCode(e.target.value)}
            defaultValue={postalCode}
          />
          {false && (
            <div className={styles.checkField}>
              <input
                type="checkbox"
                id="small_delivery"
                onChange={(e) => setSmallDelivery(e.target.checked)}
              />
              <label htmlFor="small_delivery">
                Small delivery vehicle required?
              </label>
            </div>
          )}
        </div>
        <div className={styles.field}>
          <label htmlFor="email">Email</label>
          <small>For order confirmation & contact</small>
          <input
            className={styles.input}
            id="email"
            type="email"
            placeholder="Enter email"
            onChange={(e) => setEmail(e.target.value)}
            defaultValue={email}
          />

          <div className={styles.checkField}>
            <input
              type="checkbox"
              id="signup"
              checked={signup}
              onChange={(e) => {
                setSignup(e.target.checked);
              }}
            />
            <label htmlFor="signup">
              Please tick here to sign up for news and offers
            </label>
          </div>
        </div>
      </div>

      <GoogleReCaptcha onVerify={handleVerify} />
      <div className={styles.fields}>
        <Button onClick={() => makeRequest()}>Get Your Quote</Button>
      </div>
    </div>
  );
}
