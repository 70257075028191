import styles from "./UspBar.module.css";
import { ReactComponent as Competative } from "./svgs/competative.svg";
import { ReactComponent as Basket } from "./svgs/basket.svg";
import { ReactComponent as Van } from "./svgs/van.svg";
import { ReactComponent as Face } from "./svgs/face.svg";

export function UspBar() {
  return (
    <div className={styles.UspBar}>
      <ul>
        <li>
          <Competative /> Competitive
        </li>
        <li>
          <Basket /> Quick & Easy Ordering
        </li>
        <li>
          <Van /> Free Deliveries
        </li>
        <li>
          <Face /> No Service Charge
        </li>
      </ul>
    </div>
  );
}
