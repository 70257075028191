import React, { createContext, useContext, useState, useCallback } from "react";

export interface QuoteInterface {
  quantity: number;
  postalCode: string;
  email: string;
  smallDelivery: boolean;
  setQuantity: (quantity: number) => void;
  setPostalCode: (postalCode: string) => void;
  setEmail: (email: string) => void;
  setSmallDelivery: (smallDelivery: boolean) => void;
  endpoint: string;
  setEndpoint: (endpoint: string) => void;
}

export interface QuoteProviderProps{
  children: React.ReactNode;
  defaultEndpoint: string;
}
export const QuoteContext = createContext<QuoteInterface | undefined>(
  undefined
);

export const useQuote = (): QuoteInterface => {
  const context = useContext(QuoteContext);
  if (!context) {
    throw new Error("useQuote must be used within a QuoteProvider");
  }
  return context;
};

export const QuoteProvider = ({ defaultEndpoint, children }: QuoteProviderProps ) => {
  const [quantity, setQuantity] = useState(500);
  const [postalCode, setPostalCode] = useState("");
  const [email, setEmail] = useState("");
  const [smallDelivery, setSmallDelivery] = useState(false);
  const [endpoint, setEndpoint] = useState(defaultEndpoint);

  // Define your validation logic here if you want, for example:
  const validatedSetQuantity = useCallback((newQuantity: number) => {
    if (newQuantity >= 0) {
      // Replace with your actual validation logic
      setQuantity(newQuantity);
    }
  }, []);

  const validatedSetPostalCode = useCallback((newPostalCode: string) => {
    if (newPostalCode.length > 0) {
      //uppercase postcode
      newPostalCode = newPostalCode.toUpperCase();
      setPostalCode(newPostalCode);
    }
  }, []);

  const validatedSetEmail = useCallback((newEmail: string) => {
    if (newEmail.includes("@")) {
      // Replace with your actual validation logic
      setEmail(newEmail);
    }
  }, []);

  const validatedSetSmallDelivery = useCallback((newSmallDelivery: boolean) => {
    // Add validation logic here if needed
    setSmallDelivery(newSmallDelivery);
  }, []);

  return (
    <QuoteContext.Provider
      value={{
        quantity,
        postalCode,
        email,
        smallDelivery,
        setQuantity: validatedSetQuantity,
        setPostalCode: validatedSetPostalCode,
        setEmail: validatedSetEmail,
        setSmallDelivery: validatedSetSmallDelivery,
        endpoint,
        setEndpoint,
      }}
    >
      {children}
    </QuoteContext.Provider>
  );
};
