import r2wc from "@r2wc/react-to-web-component";
import App from "./App";

const QuoteWC = r2wc(App, {
  props: {
    endpoint: "string",
  },
});

customElements.define("quote-wc", QuoteWC);
