import { createContext, useCallback, useContext, useState } from "react";

interface StepInterface {
  step: number;
  setStep: (value: number) => void;
  title: string;
}

const StepContext = createContext<StepInterface | undefined>(undefined);

const stepTitles = [
  "Get an Instant Quote",
  "Your quote and delivery options",
  "Upgrade to Premium Kerosene",
  "Delivery",
];

export const useStep = (): StepInterface => {
  const context = useContext(StepContext);
  if (!context) {
    throw new Error("useStep must be used within a StepProvider");
  }
  return context;
};

export const StepProvider = ({ children }: { children: React.ReactNode }) => {
  const [step, setRawStep] = useState(0);

  const setStep = useCallback((value: number) => {
    // Validation logic
    if (value < 4) {
      setRawStep(value);
      // scroll to id ho-widget
      const element = document.getElementById("ho-widget");
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }
    } else {
      console.error("Invalid step value:", value);
    }
  }, []);

  const title = stepTitles[step] || "Unknown";

  return (
    <StepContext.Provider value={{ step, setStep, title }}>
      {children}
    </StepContext.Provider>
  );
};
