import { useStep } from "../../context/step";
import { Delivery, Fuel, Requirements } from "./step";
import { DeliveryDate } from "./step";

export function Steps() {
  const { step } = useStep();

  switch (step) {
    case 0:
      return <Requirements />;
    case 1:
      return <DeliveryDate />
    case 2:
      return <Fuel />;
    case 3:
      return <Delivery />;
    default:
      return <>ERROR</>;
  }
}
